<template>
    <div class="card activities">
        <div v-for="item in items" :key="item.id" class="activity">
            <div class="activity-date">
                <div>
                    {{ formatDate(item.created_date) }}
                </div>
                <div class="activity-time">
                    {{ formatTime(item.created_date) }}
                </div>
            </div>
            <div class="activity-body">
                <div class="activity-sender">{{ item.sender.sender_name }}</div>
                <div class="activity-message" v-html="item.message.body"></div>
            </div>
        </div>
        <div v-if="viewCommentForm">
            <form class="form"
                  novalidate="novalidate"
                  id="st_app_comment_form"
            >
                <div class="row pb-6">
                    <div class="col">
                        <div class="form-group st-form-group-textarea mt-6">
                            <label>
                                {{fields.message_comment.label}}
                            </label>
                            <b-form-textarea
                                :ref="fields.message_comment.name"
                                :name="fields.message_comment.name"
                                v-model="model[fields.message_comment.name]"
                                class="st-form-textarea"
                                rows="5"
                                no-resize
                                :maxlength="maxlength"
                            >
                            </b-form-textarea>
                        </div>
                        <div class="d-flex justify-content-end mt-2">
                            <span>{{ remainingChars }} / {{ maxlength }}</span>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-start mt-4">
                    <div class="mb-6">
                        <st-button
                            :callback="sendMessage"
                        >
                            {{$t('APPLICATION.FORM.SEND_MESSAGE_BUTTON')}}
                        </st-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ComplainModel } from '@/modules/complain/complain-model';
import { createFormValidation } from '@/shared/utils/create-form-validation';
import moment from 'moment';

const { fields } =  ComplainModel;

const formSchema = new FormSchema([
   fields.message_comment,
]);
export default {
    name: 'ComplainActivities',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        hasPermissionToCreateComment: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            fields,
            maxlength: 500
        }
    },
    computed: {
         ...mapGetters({
            record: 'complain/form/record'
        }),
        remainingChars() {
            return this.maxlength - this.model.message_comment.length;
        },
        viewCommentForm() {
            return this.record.status === 'new' && this.hasPermissionToCreateComment;
        },
    },
    created() {
        this.model = formSchema.initialValues({message_comment: ''});
    },
    mounted() {
        if (this.viewCommentForm) {
            this.fv = createFormValidation('st_app_comment_form', this.rules);
        }
    },
    methods: {
        ...mapActions({
            sendComment: 'complain/activity/sendComment'
        }),
        async sendMessage() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const params = {
                    id: this.record.id,
                    loadStaff: this.hasPermissionToCreateComment,
                    payload: {
                        comment: this.model.message_comment,
                        visibility: 'public'
                    }
                };
                await this.sendComment(params);
                this.$emit('updateActivities', this.record.id);
                this.model.message_comment = '';
            }
        },
        formatDate(date) {
           return moment(date).format('YYYY/MM/DD');
        },
        formatTime(date) {
           return moment(date).format('HH:mm');
        },
    },
}
</script>

<style scoped lang="scss" src="@/assets/sass/pages/activities/activities.scss"></style>
